import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AntdProvider from "providers/antd";
import React from "react";
import { HashRouter, useRoutes } from "react-router-dom";
import { APP_ROUTES } from "./routes";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const AppRoute = () => {
  const appRoute = useRoutes(APP_ROUTES);
  return appRoute;
};

function App() {
  return (
    <QueryClientProvider client={client}>
      <HashRouter>
        <AntdProvider>
          <AppRoute />
        </AntdProvider>
      </HashRouter>
    </QueryClientProvider>
  );
}

export default App;
