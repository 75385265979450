// import { getToken } from "./../../services/auth/index";
import axios, { AxiosResponse } from "axios";

export const baseUrl = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (request) => {
    // const token = getToken();
    request.baseURL = `${baseUrl}`;
    request.headers = {
      // Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };
    request.validateStatus = (_) => true;
    return Promise.resolve(request);
  },
  (error) => {
    return Promise.reject(error);
  }
);


export const throwResponse = (res: AxiosResponse) => {
  const { message } = res.data;
  if (!Array.isArray(message)) {
    throw new Error(message);
  }
  const text = message.reduce((result: string, item: string) => {
    return `${result}${item}\n`;
  }, "");
  throw new Error(text);
};

export default axios;
