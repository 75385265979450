import { ITransaction } from "./interface";
import { throwResponse } from "./../../config/axios/index";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";

export const useGetTransactions = (): UseQueryResult<ITransaction[], Error> => {
  return useQuery(["get-transactions"], async () => {
    const res = await axios.get(
      "https://openapi.kims-rmuti.com/api/get/behaviorall",
      { baseURL: "" }
    );
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};
