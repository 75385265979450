import { Col, Row, Typography } from "antd";
import { ComponentType, FC } from "react";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

export interface ISidebarItem {
  to: string;
  title: string;
  key: string;
  icon?: ComponentType;
  children?: ISidebarItem[];
}

const SidebarItemWrapper = styled.div<{ $active: boolean }>`
  ${tw`p-4 mb-2 rounded  cursor-pointer transition`};
  ${({ $active }) => ($active ? tw`bg-primaryDark` : tw`bg-primaryLight`)};

  .anticon svg * {
    ${({ $active }) => ($active ? tw`fill-colors-primaryActive` : tw`fill-colors-white`)};
  }
  .ant-typography {
    ${({ $active }) => ($active ? tw`!text-primaryActive` : "")};
  }

  .anticon {
    font-size: 20px;
  }
  &:hover {
    ${tw`bg-primaryDark`};
    .anticon svg * {
      ${tw`fill-colors-primaryActive`};
    }
    .ant-typography {
      ${tw`!text-primaryActive`};
    }
  }
`;

const SidebarItem: FC<ISidebarItem> = ({ to, title, icon, children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const Icon = icon!;

  const onNavigate = () => {
    navigate(to, { replace: true });
  };

  return (
    <SidebarItemWrapper $active={pathname === to} onClick={onNavigate}>
      <Row align="middle" gutter={12}>
        <Col span={4} className="center">
          <Icon />
        </Col>
        <Col span={20}>
          <Typography.Title className="!text-white" level={5}>
            {title}
          </Typography.Title>
        </Col>
      </Row>
    </SidebarItemWrapper>
  );
};

export default SidebarItem;
