// import FreQuencyPage from "pages/frequency";
import TransactionPage from "pages/transaction";
import AssociationRulePage from "pages/association-rule";
import { RouteObject } from "react-router-dom";
import MainLayout from "../layout";
import DashboardPage from "../pages/dashboard";
import LoginPage from "../pages/login";

export const APP_ROUTES: RouteObject[] = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: "/transaction",
        element: <TransactionPage />,
      },
      // {
      //   path: "/frequency",
      //   element: <FreQuencyPage />,
      // },
      {
        path: "/association-rule",
        element: <AssociationRulePage />,
      },
    ],
  },
];
