import { ConfigProvider } from "antd";
import { createContext, FC, ReactNode, useContext } from "react";
import { IAntdContext } from "./interface";

const Context = createContext<IAntdContext>({} as IAntdContext);

const AntdProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Context.Provider value={{}}>
      <ConfigProvider
        form={{ validateMessages: { required: "กรุณากรอกข้อมูล" } }}
        theme={{
          token: {
            borderRadius: 4,
            colorPrimary: "#69b1ff",
            controlHeight: 40,
          },
        }}
      >
        {children}
      </ConfigProvider>
    </Context.Provider>
  );
};

export const useAntd = () => useContext(Context);

export default AntdProvider;
