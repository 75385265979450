import { ISidebarItem } from "./sidebar-item";
import {
  DashboardOutlined,
  FileProtectOutlined,
  // BarChartOutlined,
  DotChartOutlined,
} from "@ant-design/icons";

export const APP_MENUS: ISidebarItem[] = [
  {
    title: "ภาพรวม",
    to: "/",
    key: "dashboard",
    icon: DashboardOutlined,
  },
  {
    title: "ประวัติการเข้าชม",
    to: "/transaction",
    key: "transaction",
    icon: FileProtectOutlined,
  },
  // {
  //   title: "ความถี่ข้อมูล",
  //   to: "/frequency",
  //   key: "frequency",
  //   icon: BarChartOutlined,
  // },
  {
    title: "กฏความสัมพันธ์",
    to: "/association-rule",
    key: "association-rule",
    icon: DotChartOutlined,
  },
];
