import { Layout, Image } from "antd";
import { WebLogoImage } from "assets/images";
import { APP_MENUS } from "./menu";
import SidebarItem from "./sidebar-item";

const Sidebar = () => {
  return (
    <Layout.Sider width={280} className="!bg-primary px-4">
      <LogoContainer />
      {APP_MENUS.map((item) => {
        return <SidebarItem {...item} key={item.key} />;
      })}
    </Layout.Sider>
  );
};

const LogoContainer = () => {
  return (
    <div style={{ height: 120 }} className="center">
      <Image preview={false} src={WebLogoImage} height={100} width="auto" />
    </div>
  );
};

export default Sidebar;
