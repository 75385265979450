import { Button, Col, Input, Row, Form, Typography, Image } from "antd";
import { WebLogoImage } from "assets/images";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  return (
    <Row className="h-screen overflow-hidden">
      <Col className="h-full bg-primary center" span={12}>
        <Typography.Title>ยินดีต้อนรับ</Typography.Title>
      </Col>
      <Col span={12}>
        <FormLayout />
      </Col>
    </Row>
  );
};

const FormLayout = () => {
  const navigate = useNavigate();

  const onLogin = (values: any) => {
    // navigate("/", { replace: true });
  };

  return (
    <Layout>
      <Form layout="vertical" onFinish={onLogin}>
        <Form.Item>
          <Image preview={false} src={WebLogoImage} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          label="ชื่อผู้ใช้งาน"
          name="username"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          label="รหัสผ่าน"
          name="password"
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type='primary' className="w-full" htmlType="submit">
            เข้าสู่ระบบ
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Row className="h-screen center">
      <Col xs={4} xl={8}></Col>
      <Col xs={16} xl={8}>
        {children}
      </Col>
      <Col xs={4} xl={8}></Col>
    </Row>
  );
};

export default LoginPage;
