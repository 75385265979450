import { throwResponse } from "./../../config/axios/index";
import { IAssociationRule } from "./interface";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";

export const useGetAssociationRule = (): UseQueryResult<
  { result: IAssociationRule[]; supportThreshold: number },
  Error
> => {
  return useQuery(
    ["association-rule"],
    async () => {
      const res = await axios.get("/association-rule");
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};
