import { Layout } from "antd";
import Content from "./content";
import Header from "./header";
import Sidebar from "./sidebar";

const MainLayout = () => {
  return (
    <Layout className="h-screen">
      <Sidebar />
      <Layout>
        <Header />
        <Content />
      </Layout>
    </Layout>
  );
};

export default MainLayout;
