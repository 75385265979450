import { Avatar, Col, Dropdown, Layout, Row, Typography } from "antd";
import { CaretDownOutlined, UserOutlined } from "@ant-design/icons";

const Header = () => {
  return (
    <Layout.Header className="!px-4 !bg-primary">
      {/* <Row>
        <Col className="ml-auto">
          <UserInformation />
        </Col>
      </Row> */}
    </Layout.Header>
  );
};

const UserInformation = () => {
  const onSignOut = () => {
    //
  };

  return (
    <Dropdown
      menu={{
        items: [{ label: "ออกจากระบบ", key: "sign-out", onClick: onSignOut }],
      }}
    >
      <Row
        className="cursor-pointer"
        gutter={12}
        style={{ height: 64 }}
        align="middle"
      >
        <Col className="ml-auto center">
          <Avatar className="center" icon={<UserOutlined />} />
        </Col>
        <Col className="center">
          <Typography.Title className="!text-white" level={5}>Suwat YuemKrathrok</Typography.Title>
        </Col>
        <Col>
          <CaretDownOutlined />
        </Col>
      </Row>
    </Dropdown>
  );
};

export default Header;
