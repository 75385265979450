import { Table, TableColumnsType, Typography } from "antd";
import Container from "components/display/container";
import dayjs from "dayjs";
import { useGetTransactions } from "services/transaction";
import { ITransaction } from "services/transaction/interface";
// import { IBehavior } from "services/product-behavior/interface";
import styled from "styled-components";

const Wrapper = styled.div``;

const TransactionPage = () => {
  const { isLoading, data } = useGetTransactions();

  const columns: TableColumnsType<ITransaction> = [
    {
      title: "ลำดับ",
      width: 70,
      fixed: "left",
      render: (_, __, index) => {
        return index + 1;
      },
    },
    {
      title: "IP Address",
      ellipsis: true,
      dataIndex: "unique_key",
    },
    {
      title: "วันที่",
      ellipsis: true,
      render: (_, rc) => {
        if (!rc.date) return <span>-</span>;
        return <span>{dayjs(rc.date).format("DD MMMM BBBB")}</span>;
      },
    },
    {
      title: "ชื่อผลิตภัณฑ์",
      dataIndex: "product_name",
      ellipsis: true,
    },
    {
      title: "ประเภทผลิตภัณฑ์",
      ellipsis: true,
      render: (_, rc) => {
        if (!rc.product_type) return <span>ไม่ทราบ</span>;
        return <span>{rc.product_type}</span>;
      },
    },
  ];

  return (
    <Container height="100%">
      <Table
        rowKey="behavior_id"
        title={() => <span>ประวัติการเข้าชม</span>}
        columns={columns}
        dataSource={data}
        loading={isLoading}
        pagination={{ pageSize: 10, size: "small" }}
        scroll={{ y: "58vh", x: 800 }}
      />
    </Container>
  );
};

export default TransactionPage;

{
  /* <Table
            loading={isFetching}
            columns={columns}
            dataSource={data}
            scroll={{ y: "calc(50vh - 150px)" }}
            pagination={false}
          /> */
}

// const columns: TableColumnsType<IProductBehavior> = [
//   {
//     title: "ลำดับ",
//     width: 70,
//     render: (_, __, index) => {
//       return index + 1;
//     },
//   },
//   {
//     title: "หมายเลขไอพี",
//     dataIndex: "IP",
//   },
//   {
//     title: "จำนวนการเข้าชม",
//     render: (_, rc) => {
//       return <Typography.Text>{rc.product.length} รายการ</Typography.Text>;
//     },
//   },
// ];
