import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { Col, Image, Row, Spin, Typography } from "antd";
import Container from "components/display/container";
import React from "react";
import { useGetAssociationRule } from "services/association-rule";
import { IAssociationRuleData } from "services/association-rule/interface";

const AssociationRulePage = () => {
  const { data, isLoading, isError, error } = useGetAssociationRule();

  if (isLoading) {
    return (
      <div className="h-full center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} />} />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="h-full center">
        <Typography.Title className="!text-white" level={4}>
          เกิดข้อผิดพลาด
        </Typography.Title>
        <Typography.Title className="!text-white" level={5}>
          {error?.message}
        </Typography.Title>
      </div>
    );
  }

  const tryParseNumber = (value?: number) => {
    if (!value && value !== 0) return "-";
    return value.toFixed(2);
  };

  const entireData = () => {
    return data.result
      .filter((e) => e.round !== 1 && e.data.length > 0)
      .flatMap((e) => e.data);
  };

  const entire = entireData();

  return (
    <React.Fragment>
      <Container height="auto" className="mb-5">
        <Row align="middle" gutter={12}>
          <Col>
            <Typography.Title level={4}>กฏความสัมพันธ์</Typography.Title>
          </Col>
          <Col className="ml-auto center">
            <Typography.Text>
              Support threshold : {data.supportThreshold * 100}%
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text>Total : {entire.length}</Typography.Text>
          </Col>
        </Row>
      </Container>
      <Container height="auto">
        <Row gutter={[12, 12]}>
          <Col span={1} className="text-center">
            <Typography.Text>#</Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text>Itemset</Typography.Text>
          </Col>
          <Col span={2} className="text-center">
            <Typography.Text>Confidence</Typography.Text>
          </Col>
          <Col span={2} className="text-center">
            <Typography.Text>Lift</Typography.Text>
          </Col>
          {entire.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Col span={1} className="text-center">
                  <Typography.Text>{index + 1}</Typography.Text>
                </Col>
                <Col span={19}>
                  <ProductContainer item={item} />
                </Col>
                <Col span={2} className="text-center">
                  <Typography.Text>
                    {tryParseNumber(item.confidence)}
                  </Typography.Text>
                </Col>
                <Col span={2} className="text-center">
                  <Typography.Text>{tryParseNumber(item.lift)}</Typography.Text>
                </Col>
              </React.Fragment>
            );
          })}
        </Row>
      </Container>
    </React.Fragment>
  );
};

const ProductContainer: React.FC<{ item: IAssociationRuleData }> = ({
  item,
}) => {
  return (
    <Row gutter={12}>
      {item.items.map((e, index) => {
        return (
          <React.Fragment key={e.id}>
            <Col hidden={index !== item.items.length - 1}>
              <ArrowRightOutlined className="h-full m-auto" />
            </Col>
            <Col className="flex flex-col" span={4}>
              <Image
                wrapperClassName="mx-auto block"
                src={e.url}
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
                preview={{ mask: <div>ดูรูปเต็ม</div> }}
              />
              <Typography.Paragraph
                ellipsis={{ rows: 2 }}
                className="text-center"
              >
                {e.name}
              </Typography.Paragraph>
            </Col>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default AssociationRulePage;
