import { LoadingOutlined, PieChartFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Image,
  Popover,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useGetOverview } from "services/overview";
import { IOveriewMostView } from "services/overview/interface";
import Container from "../../components/display/container";
import Column from "antd/es/table/Column";

const DashboardPage = () => {
  const { data, isLoading, isError, error } = useGetOverview();
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(12);

  if (isLoading) {
    return (
      <div className="h-full center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} />} />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div className="h-full center">
        <Typography.Title level={4}>เกิดข้อผิดพลาด</Typography.Title>
        <Typography.Title level={5}>{error?.message}</Typography.Title>
      </div>
    );
  }

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = data?.productList.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  return (
    <Row gutter={[12, 12]}>
      <Col span={12}>
        <Container padding="p-5" height="auto">
          <Header title="ผลิตภัณฑ์ทั้งหมด" />
          <div className="my-10">
            <Typography.Title className="text-center">
              {data?.totalProduct} ชิ้น
            </Typography.Title>
          </div>
        </Container>
      </Col>
      <Col span={12}>
        <Container padding="p-5" height="auto">
          <Header title="จำนวนผู้เข้าชมทั้งหมด" />
          <div className="my-10">
            <Typography.Title className="text-center">
              {data?.totalViewer} คน
            </Typography.Title>
          </div>
        </Container>
      </Col>
      <Col span={24}>
        <Container padding="p-5" height="auto">
          <Header title="ผลิตภัณฑ์ยอดนิยม 5 อันดับแรก" />
          <Row className="mt-5" gutter={[12, 12]}>
            <Col span={2}>
              <Typography.Text>ลำดับ</Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text>ชื่อผลิตภัณฑ์</Typography.Text>
            </Col>
            <Col span={6}>
              <Typography.Text>จำนวนครั้งที่ถูกเข้าถึง</Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text>รูปภาพ</Typography.Text>
            </Col>
            {data?.mostViewProduct.map((item, index) => {
              return <MostViewCard item={item} key={item.id} index={index} />;
            })}
          </Row>
        </Container>
      </Col>

      <Col span={24}>
        <Container height="auto" padding="p-5">
          <Header title="ผลิตภัณฑ์ทั้งหมด" />
          <Row className="mt-5" gutter={[12, 12]}>
            {currentProducts.map((item) => {
              return (
                <Col span={6} key={item.id}>
                  <div className="product-card">
                    <div className="image-wrapper">
                      <Image
                        className="rounded"
                        preview={false}
                        src={item.url}
                        height={200}
                        width="100%"
                      />
                      <div className="image-overlay" />
                    </div>
                    <div className="product-details">
                      <Typography.Text
                        className="product-name"
                        ellipsis={{ tooltip: item.name }}
                      >
                        {item.name}
                      </Typography.Text>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className="flex justify-center mt-5">
            <Row gutter={[24, 24]} justify="space-around" align="middle">
              <Col span={8}>
                <Button
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  ย้อนกลับ
                </Button>
              </Col>
              <Col span={8} className="text-center">
                <Typography.Text>หน้า {currentPage}</Typography.Text>
              </Col>
              <Col span={8}>
                <Button
                  disabled={indexOfLastProduct >= data?.productList.length}
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  ถัดไป
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </Col>
    </Row>
  );
};

const MostViewCard: React.FC<{ item: IOveriewMostView; index: number }> = ({
  item,
  index,
}) => {
  return (
    <React.Fragment>
      <Col span={2}>
        <Typography.Text>{index + 1}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text ellipsis={{ tooltip: item.name }}>
          {item.name}
        </Typography.Text>
      </Col>
      <Col span={6}>
        <Typography.Text>{item.count}</Typography.Text>
      </Col>
      <Col span={8}>
        <Typography.Text>
          <Image
            className="rounded"
            preview={{ mask: <div>ดูรูปเต็ม</div> }}
            height={80}
            style={{ objectFit: "cover" }}
            src={item.url}
          />
        </Typography.Text>
      </Col>
    </React.Fragment>
  );
};

const Header: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className="flex items-center">
      <div
        className="rounded-xl p-2 center mr-5"
        style={{ backgroundColor: "#EEAE2E" }}
      >
        <PieChartFilled style={{ fontSize: 26 }} />
      </div>
      <Typography.Title level={4}>{title}</Typography.Title>
    </div>
  );
};

export default DashboardPage;
